<!-- 请款统计 -->
<template>
  <div>
    <div class="main-body-filter">
      <el-date-picker
        v-model="searchDate.beginValue"
        type="month"
        placeholder="开始月份"
        format="YYYY-MM"
        value-format="YYYY-MM"
        @change="timeChange"
      />
      <el-date-picker
        v-model="searchDate.endValue"
        type="month"
        placeholder="结束月份"
        format="YYYY-MM"
        value-format="YYYY-MM"
        @change="timeChange"
      />
      <el-button type="primary" @click="loadList(true)">导出</el-button>
    </div>
    <div class="main-body-table">
      <SelectTable id="printBill" :tables="table.list" :confs="table.conf" :filters="table.columns" :operate="false"></SelectTable>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  watch,
  onMounted,
  onBeforeMount,
  getCurrentInstance,
} from "vue";

import tableColumns from '@/assets/json/carema/table/payment.json'
import SelectTable from '@/components/common/SelectTable.vue'
import { timeToDate } from "@/utils/tools.js"; // 选择日期
import { getRowSpanMethod } from "@/utils/use_span_method.js"; // 合并表格行列
import { exportToExcel } from "@/utils/out_excel.js"; // 导出表格

// 访问 globalProperties
const ctx = getCurrentInstance().appContext.config.globalProperties

// 设置开始和结束日期
const searchDate = reactive({
  beginValue: 0,
  endValue: 0,
});
// 设置日期
const timeChange = () => {
  loadList();
};

// 表格数据
const table = reactive({
  list: [],
  columns: tableColumns.list,
  conf: {
    loading: false,
    empty_text: '暂无更多数据',
    page: 1,
    size: 10000,
    sizes: [12, 50, 100, 500],
    total: 0,
  }
});

// 合并表格行列
const spanMethod = getRowSpanMethod(table.list, ["numberAll"]);

// 导出表格
const outExcel = () => {
  let headArr = []
  for (let index = 0; index < table.columns.length; index++) {
    let element = table.columns[index];
    if(element.show) {
      headArr.push(element.label)
    }
  }
  let smallArr = []
  let tableArr = []
  for (let index = 0; index < table.list.length; index++) {
    smallArr = []
    let ele = table.list[index]
    for (let i = 0; i < table.columns.length; i++) {
      if(table.columns[i].show) {
        let e = table.columns[i].prop
        smallArr.push(ele[e])
      }
    }
    tableArr.push(smallArr)
  }
  exportToExcel(tableArr, headArr, "sheet1", "请款统计");
};

// 分页-选中页
const currentChange = (page) => {
  table.page = page;
  loadList();
};

// 分页-数量改变
const sizeChange = (num) => {
  table.size = num;
  loadList();
};

// 请求列表
const loadList = (ifExport = false) => {
  // 清除现有table数据
  table.list = []
  table.loading = true
  table.empty_text = '数据查询中...'
  let data = {
    startTime: timeToDate(searchDate.beginValue),
    endTime: timeToDate(searchDate.endValue),
  }
  if (ifExport) {
    data.type = 'export'
  }
  ctx.$request_nl_.post(ctx.$api_.state.Carema.payment.list.url, data)
    .then((respon) => {
      if (respon.Code === ctx.$code_.state.success) {
        table.list = respon.Data
        if(ifExport) {
          outExcel()
        }
    } else {
      ctx.$message.error({
        message: res.Message
      });
    }
    table.loading = false
    table.empty_text = respon.Data.length > 0 ? respon.Message : "暂无更多数据"
  }).catch(error => {
    table.loading = false
    table.empty_text = "服务器连接失败，请稍后重试"
  })
}

// 初始化
onMounted(() => {
  // 初始化加载表格列表
  loadList()
})
</script>
